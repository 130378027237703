<template>
  <div>
    <b-form-row>
      <b-col cols="12" sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <h4 class="page-sub-header">Link account name</h4>
          <div class="floating-input-field">
            <b-form-input
              id="link-account-name"
              :class="{
                'is-invalid': !formPayload.accountName && formSubmitted,
              }"
              type="text"
              placeholder=" "
              v-model="formPayload.accountName"
              required
            ></b-form-input>
            <label for="link-account-name">Link account name</label>
            <b-form-invalid-feedback class="d-block" v-if="!formPayload.accountName && formSubmitted">Link account name required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="10" md="6" lg="5" xl="4" class="pt-md-4 mt-md-3">
        <b-form-group>
          <b-form-checkbox id="property-dependent" name="property-dependent" :disabled="isAddressSetupDone" v-model="formPayload.isForEachProperty">
            Property dependent
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="isAddressSetupDone">
      <b-col>
        <h6 class="font-14 mb-3">
          <InfoCircelYellow class="mr-1 mt-n1" />
          {{ getAddressSetupErrorMsg }}
        </h6>
      </b-col>
    </b-form-row>
    <div>
      <b-form-row>
        <b-col cols="12" sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <h4 class="page-sub-header">Field name</h4>
            <div class="floating-input-field">
              <b-form-input
                :id="`field-name`"
                :class="{
                  'is-invalid': !addFieldPayload.name && addFieldSubmit,
                }"
                type="text"
                placeholder=" "
                v-model="addFieldPayload.name"
                required
              ></b-form-input>
              <label :for="`field-name`">Field name</label>
              <b-form-invalid-feedback class="d-block" v-if="!addFieldPayload.name && addFieldSubmit">Field name required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <h4 class="page-sub-header">Field type</h4>
            <div class="floating-select-field">
              <b-form-select
                :id="`field-type`"
                class="form-control"
                v-model="addFieldPayload.fieldType"
                :class="{
                  'is-value-exist': addFieldPayload.fieldType != null,
                  'is-invalid': !addFieldPayload.fieldType && addFieldSubmit,
                }"
              >
                <b-form-select-option :key="i" :value="type" v-for="(type, i) in getFieldTypes">{{ type }}</b-form-select-option>
              </b-form-select>
              <label :for="`field-type`">Field type</label>
              <b-form-invalid-feedback class="d-block" v-if="!addFieldPayload.fieldType && addFieldSubmit">Field type required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <h4 class="page-sub-header">Parameter name</h4>
            <div class="floating-input-field">
              <b-form-input
                :id="`parameter-name`"
                :class="{
                  'is-invalid': !addFieldPayload.paramName && addFieldSubmit,
                }"
                type="text"
                placeholder=" "
                v-model="addFieldPayload.paramName"
              ></b-form-input>
              <label :for="`parameter-name`">Parameter name</label>
              <b-form-invalid-feedback class="d-block" v-if="!addFieldPayload.paramName && addFieldSubmit">Parameter name required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <h4 class="page-sub-header">Helper text</h4>
            <div class="floating-input-field">
              <b-form-input :id="`helper-text`" type="text" placeholder=" " v-model="addFieldPayload.helperTxt"></b-form-input>
              <label :for="`helper-text`">Helper text</label>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mb-4">
        <b-col>
          <b-button type="button" id="add-edit-form-field-btn" v-activeBlur variant="secondary" @click="addEditFieldToLinkAccount()"
            >{{ editFieldIndex > -1 ? 'Edit field' : 'Add field' }}
          </b-button>
          <b-button type="button" id="reset-form-field-btn" v-activeBlur @click="resetField()" class="ml-3" variant="outline-secondary">Reset</b-button>
        </b-col>
      </b-form-row>
    </div>
    <b-form-row>
      <b-col cols="12">
        <div class="b-table-sticky-header custom-scroll">
          <table class="table">
            <thead>
              <tr>
                <th>Field name</th>
                <th>Field type</th>
                <th>Parameter name</th>
                <th>Helper text</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="formPayload.fields.length">
                <tr v-for="(field, fIndex) in formPayload.fields" :key="fIndex">
                  <td>{{ field.name }}</td>
                  <td>{{ field.fieldType }}</td>
                  <td>{{ field.paramName }}</td>
                  <td>{{ field.helperTxt }}</td>
                  <td class="text-right pr-0 py-2">
                    <b-dropdown boundary="window" class="action-dropdown" toggle-class="btn-h-44" no-caret variant="outline-secondary" right no-flip>
                      <template #button-content>
                        Actions
                        <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
                      </template>
                      <b-dropdown-item href="javascript:void(0)" @click="editField(field, fIndex)">Edit</b-dropdown-item>
                      <b-dropdown-item href="javascript:void(0)" @click="deleteField(fIndex)">Delete</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="5" class="text-center">No fields available.</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <b-form-invalid-feedback class="d-block mb-2" v-if="!formPayload.fields.length && formSubmitted">Atleast one field required.</b-form-invalid-feedback>
      </b-col>
    </b-form-row>
    <ConfirmationModal
      :showModal="showDeleteFieldConfirmModal"
      :title="`CONFIRM`"
      message="LINK_ACCOUNT_FIELD_REMOVE"
      @onConfirm="confirmDeleteField"
      @closeConfirmModal="showDeleteFieldConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { LINK_ACCOUNT_FIELD_TYPES } from '@/utilities/settings-constants'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import InfoCircelYellow from '@/assets/svg/info-circle-yellow.svg'
import { BIconChevronDown } from 'bootstrap-vue'
export default {
  name: 'Configurations',
  props: {
    formPayload: {
      type: Object,
      required: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addFieldSubmit: false,
      showDeleteFieldConfirmModal: false,
      editFieldIndex: -1,
      addFieldPayload: {},
    }
  },
  components: {
    InfoCircelYellow,
    BIconChevronDown,
    ConfirmationModal: () => import('@/components/common/ConfirmationModal.vue'),
  },
  computed: {
    getFieldTypes() {
      return LINK_ACCOUNT_FIELD_TYPES
    },
    isAddressSetupDone() {
      return !!(this.selectedTenant.length && !this.selectedTenant[0].isAddressSetupDone)
    },
    getAddressSetupErrorMsg() {
      return DISPLAY_MESSAGES.ADDRESS_SETUP_NOT_DONE_LINK_ACCOUNT_ERROR
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  created() {
    this.assignAddFieldPayload()
  },
  mounted() {
    this.setPropertyDependent()
  },
  methods: {
    assignAddFieldPayload() {
      this.addFieldPayload = {
        name: null,
        fieldType: null,
        paramName: null,
        helperTxt: null,
        id: 0,
        linkAccountId: 0,
      }
    },
    addEditFieldToLinkAccount() {
      this.addFieldSubmit = true
      if (this.addFieldPayload.name && this.addFieldPayload.fieldType && this.addFieldPayload.paramName) {
        if (this.editFieldIndex > -1) {
          this.formPayload.fields[this.editFieldIndex] = this.addFieldPayload
        } else {
          this.formPayload.fields.push(this.addFieldPayload)
        }
        this.resetField()
      }
    },
    resetField() {
      this.addFieldSubmit = false
      this.editFieldIndex = -1
      this.assignAddFieldPayload()
    },
    editField(field, index) {
      this.editFieldIndex = index
      this.addFieldPayload = Object.assign({}, field)
    },
    deleteField(index) {
      this.editFieldIndex = index
      this.showDeleteFieldConfirmModal = true
    },
    confirmDeleteField() {
      this.formPayload.fields.splice(this.editFieldIndex, 1)
      this.showDeleteFieldConfirmModal = false
      if (this.editFieldIndex > -1) {
        this.resetField()
      }
    },
    setPropertyDependent() {
      if (this.selectedTenant.length) {
        this.formPayload.isForEachProperty = this.selectedTenant[0].isAddressSetupDone
      }
    },
  },
  watch: {
    selectedTenant() {
      this.setPropertyDependent()
    },
  },
}
</script>
